import styles from "../Reports.module.css";

export const columnTemplate = [
  {
    value: "date",
    text: "Date",
    tooltip: "Date frame period of observing advertisers activity",
  },
  {
    value: "advertiser",
    text: "Advertiser name",
    tooltip: "Advertiser name",
  },
  {
    value: "campaign_id",
    text: "Campaign ID",
    tooltip: "Unique ID of advertiser campaign",
  },
  {
    value: "campaign_name",
    text: "Campaign name",
    tooltip: "Advertiser campaign name",
  },
  {
    value: "campaign_type",
    text: "Type",
    tooltip: "Ad format (Push, Premium Push, Native, Smartlink, Contextual Ad)",
  },
  {
    value: "zone_name",
    text: "Zones",
    tooltip: "Zone where ad is displayed. It's associated with user age.",
  },
  {
    value: "impressions",
    text: "Visits/Impressions",
    tooltip: "Number that indicates how many times advertiser ad is shown to user",
  },
  {
    value: "clicks",
    text: "Clicks",
    tooltip: "Number that shows how many times a user clicked on advertiser ad",
  },
  {
    value: "conversions",
    text: "Conversions",
    tooltip: "Number of actions advertiser defines as valuable to business",
  },
  { value: "ctr", text: "CTR", tooltip: "Clickthrough rate - the number of clicks advertiser ad receives divided by the number of times advertiser ad is shown" },
  { value: "base_price", text: "CPC", tooltip: "Cost per click advertiser is paying" },
  {
    value: "ecpm",
    text: "eCPM",
    tooltip: "eCPM",
  },
  { value: "spent", text: "Income", tooltip: "Revenue or money earned" },
  { value: "expenses", text: "Expense", tooltip: "Expense of advertiser campaigns" },
  { value: "coinis_revenue", text: "Coinis revenue", tooltip: "Coinis revenue of advertiser campaigns" },
  {
    value: "subid",
    text: "SubID",
    tooltip: "Indicator of advertiser traffic source",
  },
  {
    value: "country",
    text: "Country",
    tooltip: "Country where advertiser ad is shown, from GEO targeting",
    render: item => {
      return (
        <div className={styles.countryBox}>
          {item && item !== "XX" && (
            <img
              src={`https://publisher.coinis.com/media/countries/${item.toLowerCase()}.png`}
              alt="test"
            />
          )}
          {item}
        </div>
      );
    },
  },
  {
    value: "creative_id",
    text: "Creative ID",
    tooltip: "ID of your creative a user interacted with",
  },
  {
    value: "creative_name",
    text: "Creative",
    tooltip: "Name of advertiser creative a user interacted with",
  },
  {
    value: "cr",
    text: "CR",
    tooltip: "Conversion Rate - Ratio between advertiser conversions and clicks (in CPC model)",
  },
  {
    value: "expected_adv_revenue",
    text: "Expected Revenue",
    tooltip: "Amount advertiser sets when conversion happens",
  },
  {
    value: "browser",
    text: "Browser",
    tooltip: "Browser that displayed advertiser ad, from advertiser browser targeting",
  },
  {
    value: "hour",
    text: "Hour",
    tooltip: "Hour segment in date frame period of observing advertiser activity",
  },
  {
    value: "spam_clicks",
    text: "Spam clicks",
    tooltip: "Number of clicks that happen when a fraudster executes clicks for users who haven't made them",
  },
  {
    value: "os_version",
    text: "OS version",
    tooltip: "Specific version of operating system advertiser ad was displayed onto",
  },
  {
    value: "browser_version",
    text: "Browser version",
    tooltip: "Specific version of browser that displayed advertiser ad",
  },
  {
    value: "os",
    text: "OS",
    tooltip: "Operating System advertiser ad was displayed onto",
  },
  // {
  //   value: "iab_name",
  //   text: "IAB Category name",
  //   tooltip: "IAB Category name",
  // }
];

export const columnPreset = {
  campaign_id: true,
  campaign_name: true,
  campaign_type: true,
  impressions: true,
  clicks: true,
  conversions: true,
  ctr: true,
  base_price: true,
  ecpm: true,
  spent: true,
  expenses: true,
  coinis_revenue: true,
}