import { Button, Col, DatePicker, Radio } from "antd";
import React, { useEffect } from "react";
import { Row, Select } from "antd";
import styles from "./Filters.module.css";
import { ColumnsGroup } from "./ColumnsGroup";
import moment from "moment";
import { useState } from "react";
import { columnPreset } from "../utils/columnsList";
import { reportsService } from "../../../services/reports";
import {
  generateCountries,
  // generateIabCategories,
  generateCampaigns,
  generateAdvertisers,
  generateOS,
  generateCampaignTypes,
  generateBrowsers,
  generateOptions,
} from "../utils/filterOptions";
import { openErrorNotification } from "../../../utils/notifications";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import { ColumnInfo } from "./ColumnInfo";
import { ExportOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

export const Filters = ({
  onSubmit,
  allColumns,
  dateRange,
  setDateRange,
  handleExport,
  campaign_id,
  campaign_name,
  setPageConfig,
}) => {
  const [checkedColumns, setCheckedColumns] = useState(columnPreset);
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [osLoading, setOsLoading] = useState(false);
  const [os, setOs] = useState([]);
  const campaignTypes = [
    {
      id: "Push",
      name: "Push",
    },
    {
      id: "Premium",
      name: "Premium Push",
    },
    {
      id: "Native",
      name: "Native",
    },
    {
      id: "Smartlink",
      name: "Smartlink",
    },
    {
      id: "Contextual Ad",
      name: "Contextual Ad",
    },
  ];
  const zones = [
    {
      id: "1009631",
      name: "0-3",
    },
    {
      id: "1009857",
      name: "3-10",
    },
    {
      id: "1009858",
      name: "10-20",
    },
    {
      id: "1009859",
      name: "20-35",
    },
    {
      id: "1009860",
      name: "35-50",
    },
    {
      id: "1009861",
      name: "50+",
    },
  ];
  // const [iabCategoriesLoading, setIabCategoriesLoading] = useState(false);
  // const [iabCategories, setIabCategories] = useState([]);
  const [browsersLoading, setBrowsersLoading] = useState(false);
  const [browsers, setBrowsers] = useState([]);
  const [campaignSearchTerm, setCampaignSearchTerm] = useState("");
  const [campaignAutocompleteLoading, setCampaignAutocompleteLoading] =
    useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const debounceCampaign = useDebounce(campaignSearchTerm, 300);
  const [advertiserSearchTerm, setAdvertiserSearchTerm] = useState("");
  const [advertiserAutocompleteLoading, setAdvertiserAutocompleteLoading] =
    useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const debounceAdvertiser = useDebounce(advertiserSearchTerm, 300);
  const [filters, setFilters] = useState({});

  const [columnInfoVisible, setColumnInfoVisible] = useState(false);

  useEffect(() => {
    const getCampaigns = async (campaignSearchTerm) => {
      setCampaignAutocompleteLoading(true);
      try {
        const res = await reportsService.getCampaignsAutocomplete(
          campaignSearchTerm
        );
        let newCampaigns = [...res.data];

        if (
          campaign_id &&
          newCampaigns.every((item) => item.id !== campaign_id)
        ) {
          newCampaigns.push({ id: campaign_id, name: campaign_name });
        }
        setCampaigns(newCampaigns);
      } catch (e) {
        console.log(e);
      } finally {
        setCampaignAutocompleteLoading(false);
      }
    };
    getCampaigns(debounceCampaign);
  }, [debounceCampaign, campaign_id, campaign_name]);

  useEffect(() => {
    const getAdvertisers = async (advertiserSearchTerm) => {
      setAdvertiserAutocompleteLoading(true);
      try {
        const res = await reportsService.getPartnersAutocomplete(
          advertiserSearchTerm
        );
        if (res.data) {
          setAdvertisers(res.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setAdvertiserAutocompleteLoading(false);
      }
    };
    getAdvertisers(debounceAdvertiser);
  }, [debounceAdvertiser]);

  useEffect(() => {
    const getCountries = async () => {
      setCountriesLoading(true);
      try {
        const resc = await reportsService.getAllCountries();
        if (resc.data) {
          setCountries(resc.data);
        }
      } catch {
      } finally {
        setCountriesLoading(false);
      }
    };

    const getOS = async () => {
      setOsLoading(true);
      try {
        const reso = await reportsService.getAllOS();
        if (reso.data) {
          setOs(reso.data);
        }
      } catch {
      } finally {
        setOsLoading(false);
      }
    };

    const getBrowsers = async () => {
      setBrowsersLoading(true);
      try {
        const resb = await reportsService.getAllBrowsers();
        if (resb.data) {
          setBrowsers(resb.data);
        }
      } catch {
        openErrorNotification({ message: "" });
      } finally {
        setBrowsersLoading(false);
      }
    };

    // const getIabCategories = async () => {
    //   setIabCategoriesLoading(true);
    //   try {
    //     const resi = await reportsService.getIABCategories();
    //     if (resi?.data?.items) {
    //       setIabCategories(resi.data.items);
    //     }
    //   } catch {
    //     openErrorNotification({ message: "" });
    //   } finally {
    //     setIabCategoriesLoading(false);
    //   }
    // };

    getCountries();
    getOS();
    getBrowsers();
    // getIabCategories();
  }, []);

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    onSubmit({
      filters,
      dateRange,
      checkedColumns: urlCheckedColumns || checkedColumns,
    });
    setPageConfig(({ _, pageSize }) => ({
      currentPage: 1,
      pageSize,
    }));
  };

  useEffect(() => {
    if (campaign_id) {
      onSubmit({
        filters: {
          ...filters,
          campaign_name: [campaign_name],
          campaign_id: [campaign_id],
        },
        dateRange,
        checkedColumns: checkedColumns,
      });
      setFilters((prevState) => {
        return {
          ...prevState,
          campaign_name: [campaign_name],
          campaign_id: [campaign_id],
        };
      });
    } else {
      //handleUpdate();
    }
  }, []);

  return (
    <>
      <div className={styles.filtersContainer}>
        <Radio.Group
          className={styles.dateRadioSelect}
          defaultValue="today"
          size="large"
        >
          <Radio.Button
            value="today"
            onClick={() => setDateRange({ from: moment(), to: moment() })}
          >
            Today
          </Radio.Button>
          <Radio.Button
            value="yesterday"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "days"),
                to: moment().subtract(1, "days"),
              })
            }
          >
            Yesterday
          </Radio.Button>
          <Radio.Button
            value="7days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(6, "days"),
                to: moment(),
              })
            }
          >
            Last 7 Days
          </Radio.Button>
          <Radio.Button
            value="30days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(30, "days"),
                to: moment(),
              })
            }
          >
            Last 30 Days
          </Radio.Button>
          <Radio.Button
            value="thisMonth"
            onClick={() =>
              setDateRange({
                from: moment().startOf("month"),
                to: moment().endOf("month"),
              })
            }
          >
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "months").startOf("month"),
                to: moment().subtract(1, "months").endOf("month"),
              })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <div>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col md={24} lg={12} xxl={8}>
              <RangePicker
                styles={{ width: "100%" }}
                allowClear={false}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: "1%" }} className={styles.advancedFilters}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 8, sm: 12, md: 12, lg: 16 },
            ]}
          >
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>Advertiser</span>
              <Select
                mode="multiple"
                onChange={(value) =>
                  setFilters({ ...filters, advertiser: value })
                }
                loading={advertiserAutocompleteLoading}
                filterOption={false}
                style={{ width: "100%" }}
                placeholder="Select advertiser"
                onSearch={setAdvertiserSearchTerm}
                value={filters.advertiser}
                allowClear
              >
                {generateAdvertisers(advertisers || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>Country</span>
              <Select
                loading={countriesLoading}
                mode="multiple"
                onChange={(value) =>
                  setFilters({ ...filters, countries: value })
                }
                style={{ width: "100%" }}
                placeholder="Select country"
                filterOption={(inputValue, option) =>
                  option.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                value={filters.countries}
                allowClear
              >
                {generateCountries(countries || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>Operating System</span>
              <Select
                mode="multiple"
                onChange={(value) => setFilters({ ...filters, os: value })}
                loading={osLoading}
                style={{ width: "100%" }}
                placeholder="Select OS"
                value={filters.os}
                allowClear
              >
                {generateOS(os || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>Campaign Type</span>
              <Select
                mode="multiple"
                onChange={(value) =>
                  setFilters({ ...filters, campaignTypes: value })
                }
                style={{ width: "100%" }}
                placeholder="Select campaign type"
                value={filters.campaignTypes}
                allowClear
              >
                {generateCampaignTypes(campaignTypes || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>Campaign</span>
              <Select
                mode="multiple"
                onChange={(value) =>
                  setFilters({ ...filters, campaign_id: value })
                }
                loading={campaignAutocompleteLoading}
                filterOption={false}
                style={{ width: "100%" }}
                placeholder="Select campaigns"
                onSearch={setCampaignSearchTerm}
                value={filters.campaign_name}
                allowClear
              >
                {generateCampaigns(campaigns || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>Browser</span>
              <Select
                mode="multiple"
                onChange={(value) =>
                  setFilters({ ...filters, browsers: value })
                }
                loading={browsersLoading}
                style={{ width: "100%" }}
                placeholder="Select browser"
                filterOption={(inputValue, option) =>
                  option.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                value={filters.browsers}
                allowClear
              >
                {generateBrowsers(browsers || [])}
              </Select>
            </Col>
            {/* <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>IAB Category</span>
              <Select
                mode="multiple"
                onChange={(value) =>
                  setFilters({ ...filters, iabCategories: value })
                }
                loading={iabCategoriesLoading}
                style={{ width: "100%" }}
                placeholder="Select IAB Category"
                value={filters.iabCategories}
                allowClear
              >
                {generateIabCategories(iabCategories || [])}
              </Select>
            </Col> */}
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>SubIDs</span>
              <Select
                mode="tags"
                onChange={(value) => setFilters({ ...filters, source: value })}
                // loading={iabCategoriesLoading}
                style={{ width: "100%" }}
                placeholder="Select SubIDs"
                value={filters.source}
                tokenSeparators={[",", " ", ";"]}
                allowClear
              ></Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span>Zone</span>
              <Select
                mode="tags"
                onChange={(value) => setFilters({ ...filters, zone_id: value })}
                // loading={iabCategoriesLoading}
                style={{ width: "100%" }}
                placeholder="Select Zone"
                value={filters.zone_id}
                tokenSeparators={[",", " ", ";"]}
                allowClear
              >
                {generateOptions(zones)}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <Button
                style={{ marginTop: "22px", height: "32px" }}
                type="primary"
                size="medium"
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.bottomActions}>
        <div className={styles.rightActionBtns}>
          <div>
            <ColumnsGroup
              columnsData={allColumns}
              onChange={(value) => {
                setCheckedColumns(value);
              }}
              showUpdateButton={true}
              onUpdate={handleUpdate}
              checkedColumns={checkedColumns}
            />
            <Button
              className={styles.columnsBtn}
              onClick={() => setColumnInfoVisible(true)}
            >
              Columns Info
            </Button>
          </div>
          <Button
            className={styles.columnsBtn}
            icon={<ExportOutlined />}
            onClick={handleExport}
          >
            Export to CSV
          </Button>
        </div>
      </div>
      <ColumnInfo
        visible={columnInfoVisible}
        setVisible={setColumnInfoVisible}
        allColumns={allColumns}
      />
    </>
  );
};
