import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Select, Space } from "antd";
import React from "react";

export const CustomPagination = ({ setPageConfig, pageConfig, pageSizeOptions, disabledNext }) => {
  return (
    <Space>
      <Button
        icon={<LeftOutlined />}
        disabled={pageConfig.currentPage === 1}
        onClick={() =>
          setPageConfig(({ currentPage, pageSize }) => {
            if (currentPage > 1) {
              return { currentPage: currentPage - 1, pageSize };
            }
            return { currentPage, pageSize };
          })
        }
      />
      <Button>{pageConfig.currentPage}</Button>
      <Button
        icon={<RightOutlined />}
        disabled={disabledNext}
        onClick={() => {
          setPageConfig(({ currentPage, pageSize }) => ({
            currentPage: currentPage + 1,
            pageSize,
          }));
        }}
      />
      <Select
        value={pageConfig.pageSize}
        onChange={(value) => {
          setPageConfig({
            currentPage: 1,
            pageSize: value,
          });
        }}
        options={pageSizeOptions.map((pageSize) => ({
          label: pageSize,
          value: pageSize,
        }))}
      />
    </Space>
  );
};
