import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useFetch } from "../../utils/hooks/useFetch"
import styles from './Reports.module.css';
import { Table } from "antd";
import { Filters } from "./components/Filters";
import moment from "moment";
import { reportsService } from "../../services/reports";
import { columnTemplate } from "./utils/columnsList";
import { TableTitle } from "./components/TableTitle";
import { numberWithSpaces } from "../../utils/abbreviateNumber";
import { openWarningNotification } from "../../utils/notifications";
import { useLocation } from "react-router-dom";
import { CampaignPreview } from "../../components/CampaignPreview";
import { ActionsTableCol } from "./components/ActionsTableCol";
import { CustomPagination } from "./components/CustomPagination";

export const Reports = () => {
    const [columnsData] = useState(columnTemplate);
    const [tableColumns, setTableColumns] = useState([]);
    const [pageConfig, setPageConfig] = useState({ currentPage: 1, pageSize: 30 });
    const pageSizeOptions = [10, 20, 30, 50, 100];

    const [cmpId, setCmpId] = useState();
    const [previewVisible, setPreviewVisible] = useState(false);

    const [selectedFilters, setSelectedFilters] = useState({});
    const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
    const [sorting, setSorting] = useState({
        coinis_revenue: "descend",
    });

    const urlParams = new URLSearchParams(useLocation().search);
    const campaignId = Number(urlParams.get("id"));
    const campaignName = urlParams.get("title");

    const actionTableColumn = useMemo(() => {
        return {
            value: "campaign_id",
            text: "Actions",
            render: (value) => (
                <ActionsTableCol
                    id={value}
                    setPreviewVisible={setPreviewVisible}
                    setCampaignId={setCmpId}
                />
            ),
        };
    }, []);

    const [columnsRefactored] = useState([...columnTemplate, actionTableColumn]);

    const sumOfDataRefactor = () => {
        if (sumOfData) {
            if (sumOfData[0]) {
                let sumOfDataRefactored = { ...sumOfData[0] };
                if (sumOfData[0].coinis_revenue) {
                    sumOfDataRefactored.coinis_revenue = "$ " + parseFloat(sumOfData[0].coinis_revenue).toFixed(3);
                }
                if (sumOfData[0].expenses) {
                    sumOfDataRefactored.expenses = "$ " + parseFloat(sumOfData[0].expenses).toFixed(3);
                }
                if (sumOfData[0].spent) {
                    sumOfDataRefactored.spent = "$ " + parseFloat(sumOfData[0].spent).toFixed(3);
                }
                if (sumOfData[0].base_price) {
                    sumOfDataRefactored.base_price = "";
                }
                if (sumOfData[0].ecpm) {
                    sumOfDataRefactored.ecpm = ""
                }
                if (sumOfData[0].ctr) {
                    sumOfDataRefactored.ctr = "";
                }
                if (sumOfData[0].cr) {
                    sumOfDataRefactored.cr = "";
                }
                return sumOfDataRefactored;
            }
        }
    };

    const refactorTableData = () => {
        const tableDataRefactored = tableData
            ? tableData.map((element, index) => {

                const refactor = { ...element };

                refactor.coinis_revenue = refactor.coinis_revenue && "$ " + refactor.coinis_revenue.toFixed(3);
                refactor.expenses = refactor.expenses && "$ " + refactor.expenses.toFixed(3);
                refactor.spent = refactor.spent && "$ " + refactor.spent.toFixed(3);
                refactor.base_price = refactor.base_price && "$ " + refactor.base_price.toFixed(3);
                refactor.ecpm = refactor.ecpm && "$ " + refactor.ecpm.toFixed(3);
                refactor.ctr = refactor.ctr && refactor.ctr.toFixed(2) + " %";
                refactor.cr = refactor.cr && refactor.cr.toFixed(2) + " %";

                refactor.date = moment(refactor.date, "YYYYMMDD").format("YYYY-MM-DD");
                refactor.hour = moment(refactor.hour, "H").format("HH:mm");

                refactor.impressions = numberWithSpaces(refactor.impressions);
                refactor.clicks = numberWithSpaces(refactor.clicks);
                refactor.spam_clicks = numberWithSpaces(refactor.spam_clicks);
                refactor.conversions = numberWithSpaces(refactor.conversions);
                return {
                    key: index,
                    ...refactor
                }
            }) : "";
        return tableDataRefactored;
    }

    const handleSortingClick = useCallback(
        (value, order, multi = false) => {
            setSorting(() => {
                if (!multi) {
                    return { [value]: order };
                }
                if (sorting[value] === order) {
                    const newOrdering = { ...sorting };
                    delete newOrdering[value];
                    return newOrdering;
                }
                return { ...sorting, [value]: order };
            });
        },
        [sorting]
    );

    const updateColumns = useCallback(
        value => {
            setTableColumns(() => {
                let newTableColumn = [];
                columnsRefactored
                    .filter(column => value.checkedColumns[column["value"]])
                    .forEach(function ({ text, value, tooltip, render }) {
                        newTableColumn.push({
                            title: (
                                text !== "Actions" ?
                                    <TableTitle
                                        text={text}
                                        order={sorting[value] ? sorting[value] : false}
                                        value={value}
                                        tooltip={tooltip}
                                        onSortingClick={handleSortingClick}
                                    />
                                    : <span>Action</span>
                            ),
                            dataIndex: value,
                            render,
                        });
                    });
                return newTableColumn;
            });
        }, [sorting, columnsRefactored, handleSortingClick]
    );

    const loadCampaignData = cancelToken => {
        return reportsService.getStatsPaged({
            selectedFilters,
            currentPage: pageConfig.currentPage,
            pageSize: pageConfig.pageSize,
            sorting,
            cancelToken,
            action: false,
        });
    }

    const handleExport = () => {
        if (selectedFilters.filters) {
            return reportsService.getReports({
                selectedFilters,
                currentPage: pageConfig.currentPage,
                pageSize: pageConfig.pageSize,
                sorting,
                action: false,
            });
        } else {
            openWarningNotification({
                message: "Please update filters before trying to export table.",
            });
        }
    }

    const total = [];
    const table = [];
    const rows = 0;

    const [
        {
            data: { table: tableData, total: sumOfData },
            isFetching
        },
        getCampaignData
    ] = useFetch(loadCampaignData, { table, total, rows });
    const loading = isFetching;

    useEffect(() => {
        setPageConfig({ currentPage: 1, pageSize: 30 });
    }, []);

    useEffect(() => {
        if (selectedFilters.filters) {
            getCampaignData();
        }
    }, [sorting, selectedFilters, getCampaignData, pageConfig]);

    // useEffect(() => {
    //     const selectedColumns = tableColumns.map(item => item.dataIndex);
    //     if (
    //         !selectedColumns.includes("date") &&
    //         selectedColumns.includes("coinis_revenue")
    //       ) {
    //         setSorting({ coinis_revenue: "descend" });
    //       }
    // },[tableColumns]);

    return (
        <div>
            <h4 className={styles.pageTitle}>Statistics</h4>
            <Filters
                campaign_id={campaignId}
                campaign_name={campaignName}
                onSubmit={value => {
                    updateColumns(value);
                    setSelectedFilters(value);
                }}
                allColumns={columnsData}
                dateRange={dateRange}
                setDateRange={setDateRange}
                handleExport={handleExport}
                setPageConfig={setPageConfig}
            />
            <Table
                className={styles.mainTableContainer}
                style={{ marginTop: "2%" }}
                columns={tableColumns}
                dataSource={refactorTableData()}
                loading={loading}
                scroll={{ x: "max-content", scrollToFirstrowOnChange: true }}
                pagination={false}
                footer={() => {
                    return (
                        <CustomPagination
                            pageConfig={pageConfig}
                            setPageConfig={setPageConfig}
                            pageSizeOptions={pageSizeOptions}
                            disabledNext={tableData && tableData.length < pageConfig.pageSize}
                        />
                    );
                }}
                summary={() => {
                    const sumOfDataRefactored = sumOfDataRefactor();
                    const sum = sumOfData && sumOfData[0] ? (
                        <Table.Summary.Row>
                            {tableColumns.map((el, index) => (
                                <Table.Summary.Cell key={index} index={index}>
                                    {numberWithSpaces(sumOfDataRefactored[el.dataIndex])}
                                </Table.Summary.Cell>
                            ))}
                        </Table.Summary.Row>
                    ) : null;
                    return sum;
                }}
            />
            <CampaignPreview campaignId={cmpId} setPreviewVisible={setPreviewVisible} previewVisible={previewVisible} />
        </div>
    );
}