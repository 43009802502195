import { Select } from "antd";
const { Option } = Select;

export const generateOptions = arr => {
  return arr.map(option => (
    <Option key={option.name} value={option.id}>
      {option.name}
    </Option>
  ));
}

export const generateOS = arr => {
  return arr.map(option => (
    <Option key={option.id} value={option.id}>
      {option.name}
    </Option>
  ));
}

export const generateCampaignTypes = arr => {
  return arr.map(option => (
    <Option key={option.id} value={option.name}>
      {option.name}
    </Option>
  ));
}

export const generateBrowsers = arr => {
  return arr.map(option => (
    <Option key={option.id} value={option.id}>
      {option.name}
    </Option>
  ));
}

export const generateCountries = arr => {
  return arr.map(country => (
    <Option key={country.code} value={country.code}>
      {country.name}
    </Option>
  ));
}

export const generateIabCategories = arr => {
  return arr.map(option => (
    <Option key={option.id} value={option.name}>
      {option.name}
    </Option>
  ));
}

export const generateCampaigns = arr => {
  return arr.map(option => (
    <Option key={option.id} value={option.id}>
      {option.name}
    </Option>
  ));
};

export const generateAdvertisers = arr => {
  return arr.map(option => (
    <Option key={option.id} value={option.ocamba_account_id}>
      {option.partner_name}
    </Option>
  ));
};